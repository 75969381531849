// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vr-exhibition-js": () => import("./../../../src/pages/vr-exhibition.js" /* webpackChunkName: "component---src-pages-vr-exhibition-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/Artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artwork-js": () => import("./../../../src/templates/Artwork.js" /* webpackChunkName: "component---src-templates-artwork-js" */)
}

